.first-tighter_section {
  padding-top: 1.8rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
}

.tighter_section {
  padding-bottom: 1rem;
  padding-left: 1.5rem;
}

.subsection {
  padding: 0.5rem 0.5rem 0.5rem 0;
}

.space-bottom {
  padding-bottom: 0.5rem;
}

.more-space-bottom {
  padding-bottom: 2.5rem;
}

.margin-bottom {
  margin-bottom: 0.4em;
}

.container-nudge-left {
  margin-top: 1.5rem;
  margin-left: 2rem;
}

td:first-child,
th:first-child {
  padding-left: 0 !important;
}

td.pad-left,
th.pad-left {
  padding-left: 1.5rem;
}

.hand {
  cursor: pointer;
}

.is-80 {
  width: 80% !important;
}

.flex-right {
  margin-left: auto;
}

@media print {
  .do-not-print {
    display: none;
  }
}

.qr-codes {
  display: flex;
  justify-content: space-between;
}

.explorer-name {
  text-align: center;
}

.info-table {
  table-layout: fixed;
  overflow-wrap: break-word;
}

.first-col {
  width: 220px;
}

.width-for-a4-printing {
  width: 185mm;
}

.fade-in {
  animation: fadeIn 0.8s;
}

@keyframes fadeIn {
  0% {
    color: white;
  }
  100% {
    /*color: black;*/
  }
}

.flexcolumns {
  display: flex;
  align-items: center;
}

.flexcolumn-item:last-child {
  /*margin-left: auto;*/
  margin-left: 2em;
  /*min-width: 10%;*/
}

.flexcolumn-item-wide {
  flex-grow: 9;
}

.tooltip-icon {
  margin-left: 1rem;
}

/*
This is to stop eslint naging about links
https://stackoverflow.com/a/12642009/5264
*/
.button-link {
  align-items: normal;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgb(0, 0, 238);
  border-style: none;
  box-sizing: content-box;
  color: rgb(0, 0, 238);
  cursor: pointer;
  display: inline;
  font: inherit;
  height: auto;
  padding: 0;
  perspective-origin: 0 0;
  text-align: start;
  text-decoration: underline;
  transform-origin: 0 0;
  width: auto;
}
